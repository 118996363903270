




























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import Loading from '@/elements/Loading.vue'
import TextInput from '@/elements/TextInput.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import * as communicationsStore from '@/store/communications'
import communicationsClient from '@/clients/communicationsClient'
import { EmailSMSLeagueSetting } from '@/GeneratedTypes/Communication/EmailSMSLeagueSetting'
import { EmailSMSAccount } from '../../../GeneratedTypes/Communication/EmailSMSAccount'

class LeagueSetting implements EmailSMSLeagueSetting {
  emailSmsAccountID: number
  upwardLeagueID: string | null
  emailDisplayName: string | null
  emailReplyTo: string | null
  constructor(
    emailSmsAccountID: number,
    upwardLeagueID: string | null,
    emailDisplayName: string | null,
    emailReplyTo: string | null
  ) {
    ;(this.emailSmsAccountID = emailSmsAccountID),
      (this.upwardLeagueID = upwardLeagueID),
      (this.emailDisplayName = emailDisplayName),
      (this.emailReplyTo = emailReplyTo)
  }
}
@Component({
  components: {
    Loading,
    TextInput,
    VeeValidateForm,
  },
})
export default class Settings extends Vue {
  private loading = false
  private saving = false
  leagueSetting = new LeagueSetting(-1, '', '', '')

  @Getter(communicationsStore.getterNames.currentItem, { namespace: communicationsStore.namespace })
  private readonly providerInfo!: EmailSMSAccount

  @Action(communicationsStore.actionNames.updateLeagueSettings, {
    namespace: communicationsStore.namespace,
  })
  private readonly updateLeagueSettings!: ({
    settings,
  }: {
    settings: EmailSMSLeagueSetting
  }) => Promise<EmailSMSLeagueSetting | null>

  @Watch('providerInfo')
  private async onInfoChange() {
    await this.populateSettingsDisplay()
  }
  private async submit() {
    try {
      this.saving = true
      await this.updateLeagueSettings({ settings: this.leagueSetting })
    } catch (error) {
      throw error
    } finally {
      this.saving = false
    }
  }

  /**
   * mounted
   */
  public async mounted() {
    try {
      this.loading = true
      await this.populateSettingsDisplay()
    } catch (error) {
      throw error
    } finally {
      this.loading = false
    }
  }

  private async populateSettingsDisplay() {
    try {
      const emailSmsAccountID = this.providerInfo.emailSmsAccountID

      const leagueSetting = await communicationsClient.retrieveLeagueSettings(
        this.$route.params.id,
        emailSmsAccountID
      )

      if (leagueSetting?.upwardLeagueID) {
        this.leagueSetting = leagueSetting
      } else {
        this.leagueSetting.upwardLeagueID = this.$route.params.id
        this.leagueSetting.emailSmsAccountID = emailSmsAccountID
      }
    } catch (error) {
      throw error
    }
  }
}
